/*
  Style for online chat status message (чат онлайн/офлайн).
  Snippet: snippets/snippet__chat-status.php
*/

.online-chat-status {
  font-size: 12px;

  &__online {
    color: #008000;
  }

  &__offline {
    color: #b22222;
  }
}
