.blog {
  .intro {
    font-size: 1.125rem;
    color: #4a5568;
    border-left: 4px solid #4a5568;
    padding-left: 1rem;
    margin: 2rem 0;
  }

  .tip {
    background-color: #f7fafc;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    margin: 1.5rem 0;
  }

  .tip::before {
    content: "💡 Порада:";
    font-weight: 600;
    display: block;
    margin-bottom: 0.5rem;
  }

  h2 {
    display: block;
    font-size: 1.75rem;
    margin-bottom: 1rem;
    color: #2d3748;
  }

  h3 {
    display: block;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: #4a5568;
  }

  /* Custom styling for the <a> tag */
  .styled-link {
    color: #0d6efd; /* Primary color */
    font-weight: 600;
    text-decoration: none;
    position: relative;
    transition: color 0.3s ease;
  }

  /* Hover effect */
  .styled-link:hover {
    color: #0a58ca; /* Darker shade on hover */
  }

  /* Underline effect on hover */
  .styled-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: #0d6efd;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  .styled-link:hover::after {
    transform: scaleX(1); /* Show underline on hover */
  }

  .blog-content {
    content-visibility: auto;
  }
}
