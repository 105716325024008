.custom-range::-webkit-slider-thumb {
  background-color: #000000;
}

.custom-range::-moz-range-thumb {
  background-color: #000000;
}

#qr-code-generated {
  canvas {
    max-width: 400px !important;
  }
}
