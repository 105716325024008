.header {
  &__item {
    &-link {
      color: black;
      transition: color .3s;

      &:hover {
        color: $gunmetal;
      }
    }
  }

  .dropdown-item {
    &:hover {
      background-color: transparent;
    }
  }
}

.dropdown:hover .dropdown-menu {
  display: block;
}
