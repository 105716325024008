.blog-post {
  &-gradient {
    background-image: linear-gradient(to right, #4f31df, #00c6ff);
    background-size: 150% 150%;
    animation: radialGradientAnimation 8s ease-in-out infinite;
  }

  &__main-image {
    width: 100%;
    max-height: 490px;
    object-fit: cover;
  }
}

@keyframes radialGradientAnimation {
  0% {
    background-size: 150% 150%;
    background-position: 100% 0%;
  }
  50% {
    background-size: 200% 200%;
    background-position: 50% 50%;
  }
  100% {
    background-size: 150% 150%;
    background-position: 100% 0%;
  }
}
