.our-company {
  background: linear-gradient(
                  135deg,
                  #fafafa 0%,
                  #f0f9f9 25%,
                  #e0f4f4 50%,
                  #c5ede7 75%,
                  #a0e5d6 100%
  );
}
