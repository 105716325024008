$onyx: #373938;
$light-gray: #cfd0d4;
$gunmetal: #1f3040;
$beau-blue: #c3d1dc;
$silver-chalice: #acada7;
$white: #fff;
$silver: #c8c8c8;

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  --bs-nav-pills-link-active-bg: #2d2f33;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #2d2f33 !important;
}

@media (min-width: 992px) {
  .text-justify {
    text-align: justify;
  }
}
