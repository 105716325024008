.welcome {
  padding: 100px 0;
  background: linear-gradient(60deg, rgba(22, 90, 224, 0.05) 0%, rgba(22, 90, 224, 0.35) 60%, rgba(163, 253, 117, 0.6) 100%);
  background-size: 140% 140%;
  animation: gradientAnimation 6s ease-in-out infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 40% 100%;
  }
  50% {
    background-position: 60% 0%;
  }
  100% {
    background-position: 40% 100%;
  }
}
