@import './node_modules/bootstrap/scss/bootstrap';

* {
  scrollbar-width: thin;
  scrollbar-color: #28293d #f2f2f2;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  font-size: 16px;
  color: #2d3748;
  line-height: 1.6;
  text-rendering: geometricprecision;
  background-color: $white;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

/* Enable cross-document view transitions */
@view-transition {
  navigation: auto;
}

/* Customize the default animation behavior */
::view-transition-group(root) {
  animation-duration: 0.5s;
}

/* Define a smooth fade-out animation for the old page */
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Define a smooth fade-in animation for the new page */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Apply the fade-out animation to the old page state */
::view-transition-old(root) {
  animation: fade-out 0.3s ease-in-out forwards;
}

/* Apply the fade-in animation to the new page state */
::view-transition-new(root) {
  animation: fade-in 0.3s ease-in-out forwards;
}
