/* Базові стилі для кнопки */
.telegram-btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  background-color: #0088cc;
  color: white;
  border-radius: 50px;
  font-family: Arial, sans-serif;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  z-index: 1000;
  opacity: 0; /* Початково невидима */
  transform: translateY(20px); /* Початкова позиція нижче */
}

/* Стилі для іконки */
.telegram-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  fill: white;
  transition: transform 0.3s ease;
}

/* Анімація при наведенні */
.telegram-btn:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 136, 204, 0.4);
  background-color: #0099ff;
}

/* Пульсуюча анімація */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.03); }
  100% { transform: scale(1); }
}

/* Анімація з'явлення кнопки */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Клас для активації анімації */
.telegram-btn.visible {
  opacity: 1;
  transform: translateY(0);
  animation: pulse 3s infinite;
}

/* Анімація для іконки при наведенні */
.telegram-btn:hover .telegram-icon {
  transform: rotate(10deg);
}

/* Кнопка закриття */
.close-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 22px;
  height: 22px;
  background-color: #f44336;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.close-btn:hover {
  background-color: #d32f2f;
  transform: scale(1.1);
}

.close-icon {
  width: 12px;
  height: 12px;
  fill: white;
}

/* Прихована кнопка */
.telegram-btn.hidden {
  display: none;
}
